import Head from "next/head";
import React from "react";
import { SHARING_IMAGE_URL } from "../core/constants";
import getConfig from "../core/config";

function Metatags({ metaTitle, metaDescription, metaImageUrl }) {
  return (
    <Head>
      <meta property="og:title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={
          metaImageUrl
            ? metaImageUrl
            : `${getConfig("ORIGIN")}${SHARING_IMAGE_URL}`
        }
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="Reskinned.clothing" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={
          metaImageUrl
            ? metaImageUrl
            : `${getConfig("ORIGIN")}${SHARING_IMAGE_URL}`
        }
      />
    </Head>
  );
}

export default Metatags;
